<template>
  <div class="showcase">
    <header>
      <video alt="Background" autoplay muted ref="video" poster="@/assets/img/offers/vipski/vipski.jpg" class="desktop">
        <source src="@/assets/img/offers/vipski/vipski.mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <img src="@/assets/img/offers/vipski/vipski.jpg" class="mobile">
      <div>
        <div class="image">
          <span class="close" @click="back">
            <img src="@/assets/img/showcase/back.svg" alt="Back" />
          </span>
          <img
            :src="require(`@/assets/img/offers/vipski/svg_${$i18n.locale}.svg`)"
          />
        </div>
        <footer class="desktop">
          <span></span>
          <div class="buttons">
            <ButtonSmaller @click="scrollTo('prices')">{{
              $t("vip.programme")
            }}</ButtonSmaller>
            <ButtonSmaller @click="scrollTo('programme')">{{
              $t("vip.dinner")
            }}</ButtonSmaller>
          </div>
        </footer>
      </div>
    </header>
    <div class="showcase-content" ref="prices">
      <div class="showcase-about">
        <div class="txts">
          <h2>{{ $t("vip.r.title") }}</h2>
          <p class="sttl">{{ $t("vip.r.subtitle") }}</p>
          <p class="sttl">{{ $t("vip.r.subtitle2") }}</p>
        </div>
        <div class="img-and-features">
          <p class="mini-title">{{ $t("vip.r.prices-1") }}</p>
          <div class="image">
            <img
              :src="
                require(`@/assets/img//offers/vipski/prices_${$i18n.locale}.svg`)
              "
              :alt="$t('alt.prices-table')"
            />
          </div>
        </div>
      </div>
      <p class="mini-title" ref="programme">{{ $t("vip.r.programme") }}</p>
      <div class="showcase-about showcase-about--strech">
        <div class="txts">
          <p>
            {{ $t("vip.r.programme-1") }}
          </p>
          <p>
            {{ $t("vip.r.programme-2") }}
          </p>
           <p>
            {{ $t("vip.r.programme-3") }}
          </p>
           <p>
            {{ $t("vip.r.programme-4") }}
          </p>
           <p>
            {{ $t("vip.r.programme-5") }}
          </p>
           <p>
            {{ $t("vip.r.programme-6") }}
          </p>
           <p>
            {{ $t("vip.r.programme-7") }}
          </p>
           <p>
            {{ $t("vip.r.programme-8") }}
          </p>
          <p>
            {{ $t("vip.r.programme-9") }}
          </p>
          <p>
            {{ $t("vip.r.programme-10") }}
          </p>
        </div>
        <div class="img-and-features">
          <NewYearCarousel
            :images="[
              require('@/assets/img/offers/vipski/vipski2.jpg'),
              require('@/assets/img/offers/vipski/vipski.jpg'),
            ]"
          />
        </div>
      </div>

      <p class="mini-title">{{ $t(`vip.inquiry`) }}</p>
      <Inquiry mini="true" ref="inquiry" />
      <p class="last">
        <span>{{ $t("vip.r.last-1") }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import ButtonSmaller from "@/components/ButtonSmaller";
import NewYearCarousel from "./NewYearCarousel";
import Inquiry from "./Inquiry";
export default {
  components: {
    ButtonSmaller,
    NewYearCarousel,
    Inquiry,
  },
  props: ["showcase", "previousShowcase", "nextShowcase"],
  computed: {
    show() {
      return this.$route.name === "rooms" || this.$route.name === "halls";
    },
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    scrollTo(str) {
      const el = this.$refs[str];

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/showcase.scss";
</style>
